/* eslint-disable no-console */
// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
import Services from '@/services/Services';
import * as Domain from '../models/Domain';
import * as DateHelper from '../helpers/DateHelper';

let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' CopilotHelper ';


export async function initialize(): Promise<void> {
  debug = await Services.IsDebugOverride('TodoHelper');
  debugPrefix = Services.GetGenericChalkCategory(debugPrefix.trim());
}

export function computeCopilotTip(userId: string, studios: Domain.Studio[], toDos: Domain.ToDo[] ): Domain.CopilotTip {

  // Compute firstStudio
  if (studios.length === 0) {
    const tip = new Domain.CopilotTip('firstStudio', []);
    if (debug) {console.log(debugPrefix + 'computeCopilotTip', tip);}
    return tip;
  }

  // Go through all ToDo tips and compute attention, agenda or agendaAttention
  const toDoTips: Domain.ToDoTip[] = [];
  let copilotKind: string = 'agendaNewToDo';

  for (const toDo of toDos) {
    if (toDo.isCanceled) { continue; }

    const studio: Domain.Studio | null = studios.find((item) => item.id === toDo.studioId) ?? null ;
    if (studio !== null) {
      const userState = toDo.userStates.get(userId) ?? new Domain.ToDoUserState(userId, toDo.creationInstant);
      const userInvolvement = (userState.lastInvolvement === null) ? new Domain.NoInvolvement(userId, toDo.creationInstant) : userState.lastInvolvement;
      const toDoTip = computeUserTip(userId, studio, toDo, userInvolvement);

      if ((toDoTip.kind === 'commitmentDo') || (toDoTip.kind === 'commitmentSoon')) {
        if ((copilotKind === 'agendaNewToDo') || (copilotKind === 'agendaToDo')) { copilotKind = 'agenda'; }
      } else if (toDoTip.kind === 'noTip') {
        if ((userInvolvement instanceof Domain.Postponed) || (userInvolvement instanceof Domain.Committed)) { 
          if (copilotKind === 'agendaNewToDo') { copilotKind = 'agendaToDo'; }
        } 
      } else {
        copilotKind = 'attention';
        toDoTips.push(toDoTip);
      }
    }
  }
  // Sort toDoTips
  toDoTips.sort((itemA: Domain.ToDoTip, itemB: Domain.ToDoTip) => {
    // tip priority
    if (itemA.priority < itemB.priority) {
      return 1;
    }
    if (itemA.priority > itemB.priority) {
      return -1;
    }
    // to-do owner
    if ((itemA.toDo.ownerUserId === userId) && (itemB.toDo.ownerUserId !== userId)) {
      return 1;
    }
    if ((itemB.toDo.ownerUserId === userId) && (itemA.toDo.ownerUserId !== userId)) {
      return -1;
    }
    // involvement instant
    const itemAInstant = itemA.userInvolvement instanceof Domain.Postponed ? itemA.userInvolvement.postponementInstant : itemA.userInvolvement instanceof Domain.Committed ? itemA.userInvolvement.commitmentInstant : itemA.userInvolvement.creationInstant;
    const itemBInstant = itemB.userInvolvement instanceof Domain.Postponed ? itemB.userInvolvement.postponementInstant : itemB.userInvolvement instanceof Domain.Committed ? itemB.userInvolvement.commitmentInstant : itemB.userInvolvement.creationInstant;
    if (itemAInstant.getTime() > itemBInstant.getTime()) {
      return 1;
    }
    if (itemAInstant.getTime() < itemBInstant.getTime()) {
      return -1;
    }
    // to-do creationInstant
    if (itemA.toDo.creationInstant.getTime() < itemB.toDo.creationInstant.getTime()) {
      return -1;
    }
    if (itemA.toDo.creationInstant.getTime() > itemB.toDo.creationInstant.getTime()) {
      return 1;
    }
    return 0;
  });

  if (toDoTips.length > 0) {
    const tip = new Domain.CopilotTip(toDoTips[0].category, toDoTips);
    if (debug) {console.log(debugPrefix + 'computeCopilotTip', tip);}
    return tip;
  }

  const tip = new Domain.CopilotTip(copilotKind, []);
  if (debug) {console.log(debugPrefix + 'computeCopilotTip', tip);}
  return tip;
}

export function computeUserTip(userId: string, studio: Domain.Studio, toDo: Domain.ToDo, userInvolvement: Domain.Involvement): Domain.ToDoTip {

  let tip: Domain.ToDoTip = new Domain.ToDoTip('noTip', studio, toDo, userInvolvement);

  // Find if there is a Message
  if (toDo.derivedLatestMessage.userId !== userId) {
    let isMessageForUser: boolean = false;
    if ((toDo.derivedLatestMessage instanceof Domain.ActionWithMessage) && (toDo.derivedLatestMessage.messageBody.length > 0)) {
      isMessageForUser = ((toDo.derivedLatestMessage.messageTargetUserIds.length === 0) || (toDo.derivedLatestMessage.messageTargetUserIds.includes(userId)));
    }
    const userState: Domain.ToDoUserState = toDo.userStates.get(userId) ?? new Domain.ToDoUserState(userId, toDo.creationInstant);
    if (isMessageForUser && (toDo.derivedLatestMessage.creationInstant.getTime() > userState.lastMessageSeenInstant.getTime())) {
      tip = (toDo.derivedLatestMessage instanceof Domain.Delegated) ? new Domain.ToDoTip('delegate', studio, toDo, userInvolvement, toDo.derivedLatestMessage)  : new Domain.ToDoTip('message', studio, toDo, userInvolvement, toDo.derivedLatestMessage);    
    }
  }

  if (toDo.isCanceled) { return tip; }
 
  // dueInstant
  if (toDo.dueInstant !== null) {
    if (DateHelper.isNowSoonInstant(toDo.dueInstant, (1000 * 60 * 60 * 24 * 2))) { // 2 days before
      const newTip = new Domain.ToDoTip('dueInstantSoon', studio, toDo, userInvolvement);
      tip = (newTip.priority > tip.priority) ? newTip : tip;
    }
    if (DateHelper.isNowAfterInstant(toDo.dueInstant)) { 
      const newTip = new Domain.ToDoTip('dueInstantLate', studio, toDo, userInvolvement);
      tip = (newTip.priority > tip.priority) ? newTip : tip;
    }     
  }

  const userInvolvementInstant = userInvolvement instanceof Domain.Postponed ? userInvolvement.postponementInstant : userInvolvement instanceof Domain.Committed ? userInvolvement.commitmentInstant : userInvolvement.creationInstant;

  if (toDo.isDone) {
    switch (userInvolvement.type) {
    case 'Committed':
      // CommitmentDoLate
      if (DateHelper.isNowAfterInstantDay(userInvolvementInstant)) { // after commitmentInstant day
        const newTip = new Domain.ToDoTip('userCommitmentLate', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      } 
      // CommitmentDo
      if (DateHelper.isNowDuringInstantDay(userInvolvementInstant)) { // during commitmentInstant day
        const newTip = new Domain.ToDoTip('commitmentDo', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      // CommitmentSoon
      if (DateHelper.isNowSoonInstant(userInvolvementInstant, (1000 * 60 * 60 * 12))) { // 12 hours before
        const newTip = new Domain.ToDoTip('commitmentSoon', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      break;
    }
  } else {
    switch (userInvolvement.type) {
    case 'NoInvolvement':
      // No involvement
      if (toDo.ownerUserId === userId) {
        const newTip = new Domain.ToDoTip('userNoInvolvement', studio, toDo, userInvolvement); 
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      break;
    case 'Postponed':
      // PostponementLate
      if (DateHelper.isNowDuringOrAfterInstantDay(userInvolvementInstant)) { // during or after postponementInstant
        const newTip = new Domain.ToDoTip('userPostponementLate', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      break;
    case 'Committed':
      // CommitmentDoLate
      if (DateHelper.isNowAfterInstantDay(userInvolvementInstant)) { // after commitmentInstant day
        const newTip = new Domain.ToDoTip('userCommitmentLate', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      } 
      // CommitmentDo
      if (DateHelper.isNowDuringInstantDay(userInvolvementInstant)) { // during commitmentInstant day
        const newTip = new Domain.ToDoTip('commitmentDo', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      // CommitmentSoon
      if (DateHelper.isNowSoonInstant(userInvolvementInstant, (1000 * 60 * 60 * 12))) { // 12 hours before
        const newTip = new Domain.ToDoTip('commitmentSoon', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      break;
    case 'Completed':
      // noTip
      break; 
    case 'Delegated':
    case 'Withdrawn':
      // NoTeammateInvolved
      let isNoTeammateInvolved: boolean = true;
      for (const teammate of studio.teammates.values()) {
        if (teammate.userId === userId) { continue; }
        if (teammate.departure !== null) { continue; }
        const teammateState = toDo.userStates.get(teammate.userId) ?? new Domain.ToDoUserState(teammate.userId, toDo.creationInstant);
        if (teammateState.lastInvolvement !== null) {
          const isTeammateInvolved = ((teammateState.lastInvolvement.type === 'Postponed') || (teammateState.lastInvolvement.type === 'Committed') || (teammateState.lastInvolvement.type === 'Completed') || (teammateState.lastInvolvement.type === 'Delegated'));
          isNoTeammateInvolved = isTeammateInvolved ? false : isNoTeammateInvolved;
        }
      }
      if (isNoTeammateInvolved) {
        const escalationTimestamp = userInvolvement.creationInstant.getTime() + (3 * 24 * 60 * 60 * 1000); // wait after 3 days (to skip over weekend plus 1 day)
        const escalationInstant = new Date(escalationTimestamp);    
        if (DateHelper.isNowAfterInstantDay(escalationInstant)) {
          const newTip = new Domain.ToDoTip('noTeammateInvolved', studio, toDo, userInvolvement);
          tip = (newTip.priority > tip.priority) ? newTip : tip;
        }
      }
      break;
    case 'Followed':
      // EveryoneFollowing
      let isEveryoneFollowing: boolean = true;
      for (const teammate of studio.teammates.values()) {
        if (teammate.departure !== null) { continue; }
        const teammateState = toDo.userStates.get(teammate.userId) ?? new Domain.ToDoUserState(teammate.userId, toDo.creationInstant);
        if (teammateState.lastInvolvement === null) {
          isEveryoneFollowing = false;
        } else {
          isEveryoneFollowing = isEveryoneFollowing && (teammateState.lastInvolvement.type === 'Followed');
        }
      }
      if (isEveryoneFollowing) {
        const newTip = new Domain.ToDoTip('everyoneFollowing', studio, toDo, userInvolvement);
        tip = (newTip.priority > tip.priority) ? newTip : tip;
      }
      break;
    }
  }
  return tip;
}
