/* eslint-disable no-console */
/* eslint-disable quotes */
// tslint:disable: max-line-length
import Vue from 'vue';
import Router, { Route } from 'vue-router';
import store from './vuex';
import SignIn from '../pages/SignIn.vue';
import * as AppModuleDomain from '@/models/AppModuleDomain';
import Services from '@/services/Services';
import i18n from '@/plugins/i18n';
Vue.use(Router);
let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' Router ';

export async function Initialize(): Promise<void> {
  debugPrefix = Services.Chalk.bgGreenBright(' Router ');
  debug = await Services.IsDebugOverride('Router');
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    { path: '/sign-in', name: 'sign-in', component: SignIn, meta: { noAuthRequired: true } },
    { path: '/sign-in/:reason', name: 'sign-in-with-reason', component: SignIn, meta: { noAuthRequired: true } },

    { path: '/', name: 'root' },

    { path: '/copilot', name: 'copilot', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/Copilot.vue') },
    { path: '/copilot/:toDoId/', name: 'copilot-toDoId', meta: { invalidToDoId: 'copilot' }, component: () => import(/* webpackChunkName: "other" */ '../pages/CopilotToDo.vue') },
    { path: '/agenda/upcoming', name: 'agenda-upcoming', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaUpcoming.vue') },
    { path: '/agenda/upcoming/:toDoId/', name: 'agenda-upcoming-toDoId', meta: { invalidToDoId: 'agenda-upcoming' }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaUpcomingToDo.vue') },
    { path: '/agenda/awaiting', name: 'agenda-awaiting', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaAwaiting.vue') },
    { path: '/agenda/awaiting/:toDoId/', name: 'agenda-awaiting-toDoId', meta: { invalidToDoId: 'agenda-awaiting' }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaAwaitingToDo.vue') },
    { path: '/agenda/recurring', name: 'agenda-recurring', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaRecurring.vue') },
    { path: '/agenda/recurring/:toDoId/', name: 'agenda-recurring-toDoId', meta: { invalidToDoId: 'agenda-recurring' }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaRecurringToDo.vue') },
    { path: '/agenda/following', name: 'agenda-following', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaFollowing.vue') },
    { path: '/agenda/following/:toDoId/', name: 'agenda-following-toDoId', meta: { invalidToDoId: 'agenda-following' }, component: () => import(/* webpackChunkName: "other" */ '../pages/AgendaFollowingToDo.vue') },
    { path: '/small-wins/user', name: 'small-wins-user', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/SmallWinsUser.vue') },
    { path: '/small-wins/user/:toDoId/:progressId/', name: 'small-wins-user-toDoId-progressId', meta: { invalidProgressId: 'small-wins-user' }, component: () => import(/* webpackChunkName: "other" */ '../pages/SmallWinsUserToDo.vue') },
    { path: '/small-wins/all', name: 'small-wins-all', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/SmallWinsAll.vue') },
    { path: '/small-wins/all/:toDoId/:progressId/', name: 'small-wins-all-toDoId-progressId', meta: { invalidProgressId: 'small-wins-all' }, component: () => import(/* webpackChunkName: "other" */ '../pages/SmallWinsAllToDo.vue') },

    { path: '/todos/:toDoId', name: 'toDo', meta: { invalidToDoId: 'copilot' }, component: () => import(/* webpackChunkName: "other" */ '../pages/ToDo.vue') },

    { path: '/studios', name: 'studios', meta: { }, component: () => import(/* webpackChunkName: "other" */ '../pages/Studios.vue') },
    { path: '/studios/:studioId', name: 'studio', meta: { invalidStudioId: 'studios' } },
    { path: '/studios/:studioId/info', name: 'studio-info', meta: { invalidStudioId: 'studios', back: { id: 'studio', cols: 2, route: 'studios', params: ['studioId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioInfo.vue') },
    { path: '/studios/:studioId/waiting', name: 'studio-waiting', meta: { invalidStudioId: 'studios', back: { id: 'studio', cols: 2, route: 'studios', params: ['studioId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioWaiting.vue') },
    { path: '/studios/:studioId/waiting/:toDoId/', name: 'studio-waiting-toDoId', meta: { invalidStudioId: 'studios', invalidToDoId: 'studio-waiting', back: { id: 'toDo', cols: 3, route: 'studio-waiting', params: ['toDoId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioWaitingToDo.vue') },
    { path: '/studios/:studioId/in-progress', name: 'studio-in-progress', meta: { invalidStudioId: 'studios', back: { id: 'studio', cols: 2, route: 'studios', params: ['studioId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioInProgress.vue') },
    { path: '/studios/:studioId/in-progress/:toDoId', name: 'studio-in-progress-toDoId', meta: { invalidStudioId: 'studios', invalidToDoId: 'studio-in-progress', back: { id: 'toDo', cols: 3, route: 'studio-in-progress', params: ['toDoId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioInProgressToDo.vue') },
    { path: '/studios/:studioId/done', name: 'studio-done', meta: { invalidStudioId: 'studios', back: { id: 'studio', cols: 2, route: 'studios', params: ['studioId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioDone.vue') },
    { path: '/studios/:studioId/done/:toDoId', name: 'studio-done-toDoId', meta: { invalidStudioId: 'studios', invalidToDoId: 'studio-done', back: { id: 'toDo', cols: 3, route: 'studio-done', params: ['toDoId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioDoneToDo.vue') },
    { path: '/studios/:studioId/teammates', name: 'studio-teammates', meta: { invalidStudioId: 'studios', back: { id: 'studio', cols: 2, route: 'studios', params: ['studioId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioTeammates.vue') },
    { path: '/studios/:studioId/teammates/:teammateId', name: 'studio-teammates-teammateId', meta: { invalidStudioId: 'studios', invalidTeammateUserId: 'studio-teammates', back: { id: 'teammate', cols: 3, route: 'studio-teammates', params: ['teammateId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioTeammatesTeammate.vue') },
    { path: '/studios/:studioId/teammates/:teammateId/agenda/:toDoId', name: 'studio-teammates-teammateId-toDoId', meta: { invalidStudioId: 'studios', invalidTeammateUserId: 'studio-teammates', invalidTeammateToDoId: 'studio-teammates-teammateId', back: { id: 'toDo', cols: 4, route: 'studio-teammates-teammateId', params: ['toDoId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioTeammatesTeammateToDo.vue') },
    { path: '/studios/:studioId/logbook', name: 'studio-logbook', meta: { invalidStudioId: 'studios', back: { id: 'studio', cols: 2, route: 'studios', params: ['studioId'] } }, component: () => import(/* webpackChunkName: "other" */ '../pages/StudioLogbook.vue') },

    { path: '/profile', name: 'profile', component: () => import(/* webpackChunkName: "other" */ '../pages/Profile.vue') },
    { path: '/subscription', name: 'subscription', component: () => import(/* webpackChunkName: "other" */ '../pages/Subscription.vue') },
    { path: '/installation', name: 'installation', component: () => import(/* webpackChunkName: "other" */ '../pages/Installation.vue') },
    { path: '/about', name: 'about', meta: { noAuthRequired: true }, component: () => import(/* webpackChunkName: "other" */ '../pages/About.vue') },

    { path: '/inv/:id', name: 'invite', meta: {}, component: () => import(/* webpackChunkName: "other" */ '../pages/Invite.vue') },

    { path: '/new-toDo', name: 'new-toDo', meta: { noAuthRequired: false }, component: () => import(/* webpackChunkName: "other" */ '../pages/NewToDo.vue') },

    { path: '/error/:code', name: 'error-with-code', meta: { noAuthRequired: true }, component: () => import(/* webpackChunkName: "other" */ '../pages/Error.vue') },
    { path: '/error', name: 'error', meta: { noAuthRequired: true }, component: () => import(/* webpackChunkName: "other" */ '../pages/Error.vue') },
    { path: '/unauthorized', name: 'unauthorized', component: () => import(/* webpackChunkName: "other" */ '../pages/Unauthorized.vue') },
    { path: '*', name: 'not-found', meta: { noAuthRequired: true }, component: () => import(/* webpackChunkName: "other" */ '../pages/NotFound.vue') },
  ],
});

const appName = i18n.t('G.appName');

router.beforeEach(async (to: Route, from: Route, next: any) => {
  try {
    if (debug) { console.group(debugPrefix, `Routing to ${to.name} from ${from.name}`, to, from); }

    // Process route name
    if ((to.name === null) || (to.name === undefined)) {
      next({ name: 'error' });
      return;  // next() must ba called only once
    } else if (to.matched.some((record) => record.meta.noAuthRequired)) { // route do NOT require authentication
      next();
      return;  // next() must ba called only once
    } else { // route require authentication]
      if (!store.getters['authentication/isSignedIn']) { // auth page, goto sign with redirect
        next({ name: 'sign-in', query: { redirect: to.fullPath } });
        return;  // next() must ba called only once
      } else if (store.state.authentication.user === null) {
        next(false);
        return;  // next() must ba called only once
      } else if (store.state.authentication.user !== null && store.state.authentication.user.isProfileConfirmed === false && to.name !== 'copilot' && to.name !== 'invite') {
        next({ name: 'copilot' });
        return;  // next() must ba called only once
      } else if (store.state.authentication.user !== null && store.state.authentication.user.isProfileConfirmed === false && to.name === 'copilot') {
        next(); // break the chain here otherwise it will loop forever
        return;  // next() must ba called only once
      } else if (to.name === 'root') {
        // studioId has the same id as UserId
        next({ name: 'copilot' });
        return;  // next() must ba called only once
      } else if (to.name === 'studio') {
        if ((to.params !== undefined) && (to.params.studioId !== undefined)) {
          if (isInvalidStudioId(to.params.studioId)) {
            next({ name: 'studios' });
            return;  // next() must ba called only once
          } else {
            next({ name: 'studio-waiting', params: { studioId: to.params.studioId } });
            return;  // next() must ba called only once
          }
        } else {
          next({ name: 'studios' });
          return;  // next() must ba called only once
        }
      } else {
        // Process route parameters
        if ((to.meta !== undefined) && (to.meta.invalidStudioId !== undefined)) {
          if ((to.params !== undefined) && (to.params.studioId !== undefined)) {
            if (isInvalidStudioId(to.params.studioId)) {
              next({ name: to.meta.invalidStudioId });
              return;  // next() must ba called only once
            }
          } else {
            next({ name: to.meta.invalidStudioId });
            return;  // next() must ba called only once
          }
        }
        if ((to.meta !== undefined) && (to.meta.invalidToDoId !== undefined)) {
          if ((to.params !== undefined) && (to.params.toDoId !== undefined) && (to.params.studioId !== undefined)) {
            if (isInvalidToDoId(to.params.toDoId, to.params.studioId)) {
              next({ name: to.meta.invalidToDoId, params: { studioId: to.params.studioId } });
              return;  // next() must ba called only once
            }
          } else if ((to.params !== undefined) && (to.params.toDoId !== undefined) && (to.params.studioId === undefined)) {
            if (isInvalidToDoId(to.params.toDoId)) {
              next({ name: to.meta.invalidToDoId });
              return;  // next() must ba called only once
            }
          } else if ((to.params !== undefined) && (to.params.toDoId === undefined) && (to.params.studioId === undefined)) {
            next({ name: to.meta.invalidToDoId });
            return;  // next() must ba called only once
          }
        }
        if ((to.meta !== undefined) && (to.meta.invalidProgressId !== undefined)) {
          if ((to.params !== undefined) && (to.params.toDoId !== undefined) && (to.params.progressId !== undefined)) {
            if (isInvalidProgressId(to.params.toDoId, to.params.progressId, store.state.authentication.user.id)) {
              next({ name: to.meta.invalidProgressId });
              return;  // next() must ba called only once
            }
          } else {
            next({ name: to.meta.invalidProgressId });
            return;  // next() must ba called only once
          }
        }
        if ((to.meta !== undefined) && (to.meta.invalidTeammateUserId !== undefined)) {
          if ((to.params !== undefined) && (to.params.studioId !== undefined) && (to.params.teammateId !== undefined)) {
            if (isInvalidTeammateUserId(to.params.studioId, to.params.teammateId)) {
              next({ name: to.meta.invalidTeammateUserId, params: { studioId: to.params.studioId } });
              return;  // next() must ba called only once
            }
            if ((to.meta !== undefined) && (to.meta.invalidTeammateToDoId !== undefined)) {
              if ((to.params !== undefined) && (to.params.toDoId !== undefined)) {
                if (isInvalidToDoId(to.params.toDoId, to.params.studioId)) {
                  next({ name: to.meta.invalidTeammateToDoId, params: { studioId: to.params.studioId, teammateId: to.params.teammateId } });
                  return;  // next() must ba called only once
                }
              }
            }
          } else {
            next({ name: to.meta.invalidTeammateUserId, params: { studioId: to.params.studioId } });
            return;  // next() must ba called only once
          }
        }

        next();  // all good, let the route continue
      }
    }
  } catch (e) {
    next({ name: 'error' });
  } finally {
    if (debug) { console.groupEnd(); }
  }
});

function isInvalidStudioId(studioId: string): boolean {
  const studio = store.state.studio.studios.get(studioId);
  if ((studio !== undefined)){
    return false;
  }
  return true;
}

function isInvalidToDoId(toDoId: string, studioId: string | null = null): boolean {
  const toDo = store.state.studio.toDos.get(toDoId);
  if (toDo !== undefined) {
    if (studioId === null) {
      return false;
    } else {
      if (toDo.studioId === studioId) { return false; }
    }
  }
  return true;
}

function isInvalidProgressId(toDoId: string, progressId: string, userId: string): boolean {
  const toDo = store.state.studio.toDos.get(toDoId);
  if (toDo !== undefined) {
    const action = toDo.actions.get(progressId);
    if (action !== undefined) {
      if ((action.type === 'Progressed') || (action.type === 'Completed')) { return false; }
    }
  }
  return true;
}

function isInvalidTeammateUserId(studioId: string, teammateId: string): boolean {
  const studio = store.state.studio.studios.get(studioId);
  if (studio !== undefined) {
    if (studio.teammates.get(teammateId) !== undefined) { return false; }
  }
  return true;
}

router.afterEach((to: Route, from: Route) => {
  // change route
  store.commit(AppModuleDomain.ChangedRouteMutation.MutationName, new AppModuleDomain.ChangedRouteMutation(to, from));

  // set last studioId
  if (to.name && to.name.startsWith('studio-')) {
    if (to.params.studioId !== store.state.app.lastStudioId) {
      store.commit(AppModuleDomain.SetLastStudioRouteStudioIdMutation.MutationName, new AppModuleDomain.SetLastStudioRouteStudioIdMutation(to.params.studioId));
    }
  }

  // change document title
  let documentTitle = appName;

  if (to.name && to.name.startsWith('copilot')) {
    documentTitle = `${documentTitle} - Copilot`;
  }
  if (to.name && to.name.startsWith('agenda')) {
    documentTitle = `${documentTitle} - Agenda`;
  }
  if (to.name && to.name.startsWith('progress')) {
    documentTitle = `${documentTitle} - Progress`;
  }

  if (to.name && to.name.startsWith('studio-')) {
    const studio = store.state.studio.studios.get(to.params.studioId);
    if (studio !== undefined) { documentTitle = `${documentTitle} - ${studio.title}`; }
  }

  (document as any).title = documentTitle;
});

export default router;
