<template>
  <div class="ma-0 pa-0">
    <v-navigation-drawer app v-model="drawer" left fixed width="240" temporary color="white" mobile-breakpoint="0" class="ma-0 pa-0" >
      <v-list ripple class="ma-0 pa-0">
        <v-list-item class="primary ma-0 pl-4 py-0">
          <v-list-item-action class="ma-0 pr-4">
            <t-icon href="#logo" :title="$t('C.THeader.appSlogan')" color="white" :size="36"/>
          </v-list-item-action>
          <v-list-item-content>
            <div class="subtitle-2 white--text">{{ $t('G.appName') }}</div>
          </v-list-item-content>
        </v-list-item>
        <template v-if="isSignedIn">       
          <v-list-item v-if="activeBtn === 'copilot'" style="background-color:#E3F2FD" inactive>
            <v-list-item-action>
              <t-icon href="#copilot" title="$t('C.THeader.copilot')" color="primary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{ $t('C.THeader.copilot') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else @click="onDrawerMenu('copilot')" :ripple="{ class: 'primary--text' }">
            <v-list-item-action>
              <t-icon href="#copilot" title="$t('C.THeader.copilot')" color="secondary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">{{ $t('C.THeader.copilot') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="activeBtn === 'agenda'" style="background-color:#E3F2FD" inactive>
            <v-list-item-action>
              <t-icon href="#agenda" title="$t('C.THeader.agenda')" color="primary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{ $t('C.THeader.agenda') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else @click="onDrawerMenu('agenda-upcoming')" :ripple="{ class: 'primary--text' }">
            <v-list-item-action>
              <t-icon href="#agenda" title="$t('C.THeader.agenda')" color="secondary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">{{ $t('C.THeader.agenda') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="activeBtn === 'smallWins'" style="background-color:#E3F2FD" inactive>
            <v-list-item-action>
              <t-icon href="#small-wins" title="$t('C.THeader.smallWins')" color="primary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{ $t('C.THeader.smallWins') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else @click="onDrawerMenu('small-wins-all')" :ripple="{ class: 'primary--text' }">
            <v-list-item-action>
              <t-icon href="#small-wins" title="$t('C.THeader.smallWins')" color="secondary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">{{ $t('C.THeader.smallWins') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="activeBtn === 'studios'" style="background-color:#E3F2FD" inactive>
            <v-list-item-action>
              <t-icon href="#studio" title="$t('C.THeader.studios')" color="primary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{ $t('C.THeader.studios') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else @click="onDrawerMenu('studios')" :ripple="{ class: 'primary--text' }">
            <v-list-item-action>
              <t-icon href="#studio" title="$t('C.THeader.studios')" color="secondary" :size="24"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">{{ $t('C.THeader.studios') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider color="secondary" class="my-2 pa-0"></v-divider>
        </template>
        <v-list-item v-if="activeBtn === 'feedback'" style="background-color:#E3F2FD" inactive>
          <v-list-item-action>
            <t-icon href="#megaphone" title="$t('C.THeader.feedback')" color="primary" :size="24"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{ $t('C.THeader.feedback') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else @click="openFeedbackDialog(true)" :ripple="{ class: 'primary--text' }">
          <v-list-item-action>
            <t-icon href="#megaphone" title="$t('C.THeader.feedback')" color="secondary" :size="24"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="secondary--text">{{ $t('C.THeader.feedback') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider color="secondary" class="my-2 pa-0"></v-divider>
        <v-list-item v-if="activeBtn === 'about'" style="background-color:#E3F2FD" inactive>
          <v-list-item-action>
            <t-icon href="#info-outline" title="$t('C.THeader.about')" color="primary" :size="24"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{ $t('C.THeader.about') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else @click="onDrawerMenu('about')" :ripple="{ class: 'primary--text' }">
          <v-list-item-action>
            <t-icon href="#info-outline" title="$t('C.THeader.about')" color="secondary" :size="24"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="secondary--text">{{ $t('C.THeader.about') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed class="ma-0 pa-0" :color="backgroundColor" :height="56" :extensionHeight="extensionHeight" data-testid="appBar">
      <template v-if="isBackBtn">
        <v-btn min-width="100" large rounded style="border-radius: 12px;" class="button primary white--text text-none ma-0 px-2" @click="onGoToBack" data-testid="BackButton">
          <v-layout column align-start class="ma-0 pa-0">
            <v-layout row align-center class="ma-0 pa-0">
              <t-icon href="#arrow-left" title="$t('C.THeader.back')" color="white" :size="24" class="mr-1 my-0 pa-0"/>
              <span class="mr-2 my-0 pa-0">{{ $t('C.THeader.back') }}</span>
            </v-layout> 
          </v-layout>
        </v-btn>
        <v-toolbar-title class="ma-0 pa-0">
          <div v-if="isToDoBackBtn" class="subtitle-1 font-weight-black ml-8 my-0 pa-0" data-testid="ToDoBackLabel">{{ $t('C.THeader.to-do') }}</div>
          <div v-if="isTeammateBackBtn" class="subtitle-1 font-weight-black ml-8 my-0 pa-0" data-testid="TeammateBackLabel">{{ $t('C.THeader.teammate') }}</div>
          <div v-if="isStudioBackBtn" class="subtitle-1 font-weight-black ml-8 my-0 pa-0" data-testid="StudioBackLabel">{{ $t('C.THeader.studio') }}</div>
        </v-toolbar-title>
      </template>
      <template v-else>
        <v-app-bar-nav-icon :width="36" class="ma-0 pa-0" @click="drawer = !drawer" data-testid="MobileMenuButton">
        </v-app-bar-nav-icon>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp" class="ma-0 pa-0">
          <t-icon href="#logo" :title="$t('G.appName')" color="secondary" :size="36" class="ma-0 pa-0"/>
        </v-toolbar-title>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp" class="ma-0 pa-0">
          <div style="line-height: 100%;" class="subtitle-2 font-weight-black ma-0 pa-0">{{ $t('G.appName') }}</div>
        </v-toolbar-title>
        <t-header-nav-bar v-if="isSignedIn" class="ma-0 pa-0" :user="user" :activeBtn="activeBtn"/>
        <v-spacer></v-spacer>
        <v-card v-if="$vuetify.breakpoint.smAndUp" flat color="transparent" ripple @click="openFeedbackDialog(true)" class="mr-4 my-0 pa-0 px-0">
          <v-layout column align-center class="ma-0 pa-0">
            <t-icon color="#AAAAAA" href="#megaphone" :title="$t('C.THeader.feedback')" :size="24" id="scale-on-hover" class="ma-0 pa-0"/>
            <div style="font-size: 65%" class="grey--text text-none ma-0 pa-0" data-testid="btnFeedback">{{ $t('C.THeader.feedback') }}</div>
          </v-layout>
        </v-card>
        <v-btn v-if="!isSignedIn" color="primary" style="border-radius: 10px" class="white--text button text-none ma-0" @click="onSignIn" :disabled="isSignedInButtonDisabled" data-testid="signInButton">
          {{ $t('C.THeader.sign-in') }}
        </v-btn>
        <v-menu v-if="isSignedIn && isUserVisible" offset-y right>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="button text-none mt-1 mr-0 my-0" text icon data-testid="accountButton">
              <t-avatar :size="40" :initials="userInitials" :color="userColor" :picture-url="userPictureUrl" data-testid="avatar"/>
            </v-btn>
          </template>
          <v-list ripple class="ma-0 pa-0">
            <v-list-item class="primary ma-0 pa-0">
              <v-layout column align-start class="ma-0 pa-0">
                <div class="subtitle-1 font-weight-black white--text mx-3 mt-1 mb-0 pa-0">{{ userFullName }}</div>
                <div class="overline text-none white--text mx-3 mt-0 mb-1 pa-0">{{ userEmail }}</div>
              </v-layout>
            </v-list-item>
            <v-list-item v-if="activeBtn === 'profile'" style="background-color:#E3F2FD" inactive>
              <v-list-item-action>
                <t-icon href="#account-profile" title="$t('C.THeader.profile')" color="primary" :size="24"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text">{{ $t('C.THeader.profile') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="onGotoRoute('profile')" :ripple="{ class: 'primary--text' }">
              <v-list-item-action>
                <t-icon href="#account-profile" title="$t('C.THeader.profile')" color="secondary" :size="24"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">{{ $t('C.THeader.profile') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="activeBtn === 'subscription'" style="background-color:#E3F2FD" inactive>
              <v-list-item-action>
                <t-icon href="#subscription" title="$t('C.THeader.subscription')" color="primary" :size="24"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text">{{ $t('C.THeader.subscription') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="drawer = !drawer; onGotoRoute('subscription')" :ripple="{ class: 'primary--text' }">
              <v-list-item-action>
                <t-icon href="#subscription" title="$t('C.THeader.subscription')" color="secondary" :size="24"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">{{ $t('C.THeader.subscription') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider color="secondary" class="my-2 pa-0"></v-divider>
            <v-list-item v-if="activeBtn === 'sign-out'" style="background-color:#E3F2FD" inactive>
              <v-list-item-action>
                <t-icon href="#sign-out" title="$t('C.THeader.sign-out')" color="primary" :size="24"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text">{{ $t('C.THeader.sign-out') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="onSignOut" :ripple="{ class: 'primary--text' }" data-testid="signOutMenu">
              <v-list-item-action>
                <t-icon href="#sign-out" title="$t('C.THeader.sign-out')" color="secondary" :size="24"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">{{ $t('C.THeader.sign-out') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-if="isSignedIn && (extensionHeight > 0)" v-slot:extension>
        <v-card flat color="transparent" :height="extensionHeight" class="ma-0 pa-0">
        </v-card>
      </template>
    </v-app-bar>
    <t-dialog-feedback :dialog="isDialogFeedbackOpen" @cancel="openFeedbackDialog(false)" @confirm="openFeedbackDialog(false)" @store="$emit('store', $event)"/>
    <t-dialog-sign-out :key="dialogSignOutKey" :dialog="dialogSignOut" :user="user" :pendingMutationsToSyncCount="pendingMutationsToSyncCount" :isOnline="isOnline" @cancel="onCancelDialogSignOut" @confirm="onConfirmDialogSignOut"/>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import * as Domain from '../models/Domain';
import * as AuthenticationModuleDomain from '../models/AuthenticationModuleDomain';
import TIcon from '../components/TIcon.vue';
import TAvatar from '../components/TAvatar.vue';
import THeaderNavBar from '../components/THeaderNavBar.vue';
import TDialogMessage from '../components/TDialogMessage.vue';
import TDialogFeedback from '../components/TDialogFeedback.vue';
import TDialogSignOut from '../components/TDialogSignOut.vue';

@Component({
  components: {
    TIcon, TAvatar, THeaderNavBar, TDialogMessage, TDialogFeedback, TDialogSignOut, 
  },
  name: 't-header',
})
export default class THeader extends Vue {
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), required: true })
  public readonly route!: Route;
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), default: null })
  public readonly user!: Domain.User | null;
  @Prop({ type: String, default: 'dev' })
  public readonly environmentName: string;
  @Prop({ type: Boolean, default: false })
  public readonly isOnline: boolean;
  @Prop({ type: Number, default: 0 })
  public readonly pendingMutationsToSyncCount: number;
  // Events

  private dialogFeedback: boolean = false;
  public showTrainer: boolean = false;
  public drawer: boolean = false;
  public dialogSignOut: boolean = false;
  public dialogSignOutKey: string = 'dialogSignOut-0';

  public userId: string = '';
  public userNickname: string = '';
  public userInitials: string = '';
  public userFullName: string = '';
  public userEmail: string = '';
  public userColor: string = '';
  public userPictureUrl: string = '';

  private beforeMount() {
    this.dialogFeedback = false;
    this.userChanged();
  }

  @Watch('user', { immediate: false, deep: true })
  private userChanged() {
    if (this.user !== null) {
      this.userId = this.user.id;
      this.userNickname = this.user.nickname;
      this.userInitials = this.user.initials;
      this.userFullName = this.user.fullName;
      this.userEmail = this.user.email === null ? '' : this.user.email;
      this.userColor = this.user.color;
      this.userPictureUrl = this.user.pictureUrl;
    } else {
      this.userId = '00000000-0000-0000-0000-000000000000';
      this.userNickname = '';
      this.userInitials = '';
      this.userFullName = '';
      this.userEmail = '';
      this.userColor = '';
      this.userPictureUrl = '';     
    }
  }

  public get isDialogFeedbackOpen(): boolean {
    return this.dialogFeedback;
  }

  public openFeedbackDialog(value: boolean) {
    this.dialogFeedback = value;
  }

  public get contentWidth(): number {
    return (this as any).$vuetify.breakpoint.width;
  }

  public get isUserVisible(): boolean {
    return ((this.contentWidth >= 382) || ((this.contentWidth < 382) && (this.activeBtn !== 'studio')));
  }

  public get backgroundColor(): string {
    if (this.environmentName === 'cypress') { return 'white'; }
    if (this.environmentName.startsWith('dev')) { return 'red lighten-5'; }
    if (this.environmentName === 'staging') { return 'green lighten-5'; }
    return 'white';
  }

  public get isSingleColumn(): boolean {
    return (this.contentWidth < (this as any).$vuetify.breakpoint.thresholds.xs);
  }
  public get isTwoColumn(): boolean {
    return ((this.contentWidth >= (this as any).$vuetify.breakpoint.thresholds.xs) && (this.contentWidth < (this as any).$vuetify.breakpoint.thresholds.sm));
  }
  public get isThreeColumn(): boolean {
    return ((this.contentWidth >= (this as any).$vuetify.breakpoint.thresholds.sm) && (this.contentWidth < (this as any).$vuetify.breakpoint.thresholds.md));
  }
  public get isFourColumnOrMore(): boolean {
    return (this.contentWidth >= (this as any).$vuetify.breakpoint.thresholds.md);
  }

  public get isBackBtn(): boolean {
    if (this.route === null) { return false; }
    if ((this.route.name === null) || (this.route.name === undefined)) { return false; }
    if ((this.route.meta === null) || (this.route.meta === undefined)) { return false; }
    if(this.route.meta.back === undefined) { return false; }
    // check columns required to Show Back button
    if (this.isSingleColumn && (this.route.meta.back.cols <= 1)) { return false; }
    if (this.isTwoColumn && (this.route.meta.back.cols <= 2)) { return false; }
    if (this.isThreeColumn && (this.route.meta.back.cols <= 3)) { return false; }
    if (this.isFourColumnOrMore && (this.route.meta.back.cols <= 4)) { return false; }
    return true;
  }

  public get isToDoBackBtn(): boolean {
    if (this.isBackBtn === false) { return false; }
    if (this.route === null) { return false; }
    if ((this.route.meta === null) || (this.route.meta === undefined)) { return false; }
    if(this.route.meta.back === undefined) { return false; }
    return (this.route.meta.back.id === 'toDo');
  }

  public get isTeammateBackBtn(): boolean {
    if (this.isBackBtn === false) { return false; }
    if (this.route === null) { return false; }
    if ((this.route.meta === null) || (this.route.meta === undefined)) { return false; }
    if(this.route.meta.back === undefined) { return false; }
    return (this.route.meta.back.id === 'teammate');
  }

  public get isStudioBackBtn(): boolean {
    if (this.isBackBtn === false) { return false; }
    if (this.route === null) { return false; }
    if ((this.route.meta === null) || (this.route.meta === undefined)) { return false; }
    if(this.route.meta.back === undefined) { return false; }
    return (this.route.meta.back.id === 'studio');
  }

  public get extensionHeight(): number {
    return 0;
  }

  public get activeBtn(): string {
    if (this.route === null) { return 'copilot'; }
    if ((this.route.name === null) || (this.route.name === undefined)) { return 'copilot'; }

    if (this.route.name.startsWith('error')) { return 'error'; }
    if (this.route.name.startsWith('sign-out')) { return 'sign-out'; }
    if (this.route.name.startsWith('sign-in')) { return 'sign-in'; }
    if (this.route.name.startsWith('profile')) { return 'profile'; }
    if (this.route.name.startsWith('subscription')) { return 'subscription'; }
    if (this.route.name.startsWith('installation')) { return 'installation'; }
    if (this.route.name.startsWith('feedback')) { return 'feedback'; }
    if (this.route.name.startsWith('about')) { return 'about'; }
    if (this.route.name.startsWith('copilot')) { return 'copilot'; }
    if (this.route.name.startsWith('agenda')) { return 'agenda'; }
    if (this.route.name.startsWith('small-wins')) { return 'smallWins'; }
    if (this.route.name.startsWith('studios')) { return 'studios'; }
    if (this.route.name.startsWith('studio-info')) { return 'studios'; }
    if (this.route.name.startsWith('studio-teammates')) { return 'studios'; }
    if (this.route.name.startsWith('studio-waiting')) { return 'studios'; }
    if (this.route.name.startsWith('studio-in-progress')) { return 'studios'; }
    if (this.route.name.startsWith('studio-done')) { return 'studios'; }
    if (this.route.name.startsWith('studio-logbook')) { return 'studios'; }
    if (this.route.name.startsWith('toDo')) { return 'toDo'; }
    return 'copilot';
  }

  public get isSignedIn(): boolean {
    return this.user !== undefined && this.user !== null;
  }

  public get isSignedInButtonDisabled(): boolean {
    return this.activeBtn === 'sign-in';
  }

  public onDrawerMenu(newRoute: string) {
    this.onGotoRoute(newRoute);
    this.drawer = !this.drawer;
  }

  public onGotoRoute(newRoute: string) {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'route': '" + newRoute + "' }");
    } else {
      // tslint:disable-next-line:no-empty
      this.$router.push({ name: newRoute }).catch(() => { });
    }
  }

  public onGoToBack() {
    if (this.route === null) { return; }
    if ((this.route.meta === null) || (this.route.meta === undefined)) { return; }

    const params = { ...this.route.params };
    this.route.meta.back.params.forEach((param) => {
      if (params.hasOwnProperty(param)) {
        delete params[param];
      }
    });

    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'route': '" + this.route.meta.back.route + "' }");
    } else {
      // tslint:disable-next-line:no-empty
      this.$router.push({ name: this.route.meta.back.route, params: params }).catch(() => { });
    }
  }

  public async onSignIn() {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + AuthenticationModuleDomain.SignInAction.ActionName + "' }");
    } else {
      const searchParams = new URLSearchParams(window.location.search.toLowerCase());
      let redirect: string | null = null;
      if (searchParams.has('redirect')) {
        redirect = searchParams.get('redirect');
      }
      await this.$store.dispatch(AuthenticationModuleDomain.SignInAction.ActionName, new AuthenticationModuleDomain.SignInAction(redirect));
    }
  }

  public onSignOut() {
    this.dialogSignOut = true;
    const key: number = Number(this.dialogSignOutKey.substring(14)) + 1;
    this.dialogSignOutKey = 'dialogSignOut-' + key.toString();
  }
  public onCancelDialogSignOut() {
    this.dialogSignOut = false;
  }
  public onConfirmDialogSignOut() {
    this.dialogSignOut = false;
  }
}
</script>

<style>
.v-toolbar__content {
  padding: 0px 4px 0px 4px !important;
}
.v-toolbar__extension {
  padding: 0px 4px 0px 4px !important;
}
</style>

